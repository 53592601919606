import { useEffect } from 'react'
import { usePricePillBusd } from 'state/hooks'

const useGetDocumentTitlePrice = () => {
  const pillPriceUsd = usePricePillBusd()
  useEffect(() => {
    document.title = `MatrixDefi - $${Number(pillPriceUsd).toLocaleString(undefined, {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    })}`
  })
}
export default useGetDocumentTitlePrice
