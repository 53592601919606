import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    isOldPsc: true,
    isTokenOnly: true,
    lpSymbol: 'PILL',
    lpAddresses: {
      97: '',
      56: '0x08aCe7cB18E1f441A691A518E6B9C6754d693089', // PILL-BUSD LP
    },
    tokenSymbol: 'PILL',
    tokenAddresses: {
      97: '',
      56: '0x744a23936DcB894996BDe50e94CF846471e479f6',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 1,
    isV2: true,
    lpSymbol: 'PILL-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x08aCe7cB18E1f441A691A518E6B9C6754d693089',
    },
    tokenSymbol: 'PILL',
    tokenAddresses: {
      97: '',
      56: '0x744a23936DcB894996BDe50e94CF846471e479f6',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 2,
    isV2: true,
    lpSymbol: 'PILL-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xab839A8852Af38C76c8b9475fDB94a3cBbac4314',
    },
    tokenSymbol: 'PILL',
    tokenAddresses: {
      97: '',
      56: '0x744a23936DcB894996BDe50e94CF846471e479f6',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 8,
    isV2: true,
    lpSymbol: 'PILL-CAKE LP',
    lpAddresses: {
      97: '',
      56: '0x0c3cA6215e9Bbc47426E6151F141f76551106c82',
    },
    tokenSymbol: 'PILL',
    tokenAddresses: {
      97: '',
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 11,
    isPsc: true,
    isTokenOnly: true,
    lpSymbol: 'BTCB',
    lpAddresses: {
      97: '',
      56: '0xF45cd219aEF8618A92BAa7aD848364a158a24F33', // BTCB-BUSD LP
    },
    tokenSymbol: 'BTCB',
    tokenAddresses: {
      97: '',
      56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 12,
    isPsc: true,
    isTokenOnly: true,
    lpSymbol: 'ETH',
    lpAddresses: {
      97: '',
      56: '0x7213a321F1855CF1779f42c0CD85d3D95291D34C', // ETH-BUSD LP
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '',
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 13,
    isPsc: true,
    isTokenOnly: true,
    lpSymbol: 'WBNB',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16', // BNB-BUSD
    },
    tokenSymbol: 'WBNB',
    tokenAddresses: {
      97: '',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 6,
    isV2: true,
    lpSymbol: 'CAKE-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '',
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 7,
    isV2: true,
    lpSymbol: 'CAKE-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x804678fa97d91B974ec2af3c843270886528a9E6',
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '',
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 14,
    isPsc: true,
    isTokenOnly: true,
    lpSymbol: 'XRP',
    lpAddresses: {
      97: '',
      56: '0x8339CfC9002d1ecBB23b9DE95CF17AbF0A9c2ea8', // XRP-BUSD
    },
    tokenSymbol: 'XRP',
    tokenAddresses: {
      97: '',
      56: '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 15,
    isPsc: true,
    isTokenOnly: true,
    lpSymbol: 'USDC',
    lpAddresses: {
      97: '',
      56: '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1', // USDC-BUSD
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '',
      56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 16,
    isV2: true,
    lpSymbol: 'PILL-XRP LP',
    lpAddresses: {
      97: '',
      56: '0x603b7aeBbbD94AD463B07090E5d401C2128A1684', // PILL-XRP
    },
    tokenSymbol: 'PILL',
    tokenAddresses: {
      97: '',
      56: '0x744a23936DcB894996BDe50e94CF846471e479f6',
    },
    quoteTokenSymbol: QuoteToken.XRP,
    quoteTokenAdresses: contracts.xrp,
  },
  {
    pid: 17,
    isV2: true,
    lpSymbol: 'PILL-USDC LP',
    lpAddresses: {
      97: '',
      56: '0x578c6791cbF8b52ee6581d90825223d78BC8426A', // PILL-USDC
    },
    tokenSymbol: 'PILL',
    tokenAddresses: {
      97: '',
      56: '0x744a23936DcB894996BDe50e94CF846471e479f6',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
]

export default farms
